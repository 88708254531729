import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import VideoPlayer from "../components/VideoPlayer";
import NewsletterInput from "../components/NewsletterInput";

import logo from "../img/TG_white_full.png";
import promoVideo from '../assets/TG100_promo.mp4';
import thumbnail from '../img/video_thumbnail.png'

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  sectionOne,
  intro,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
      <VideoPlayer videoSrc={promoVideo} thumbnailSrc={thumbnail} />
      {/* Greatest of all time container */}
      <div className="container">
        <div className="section">
          <h2>{sectionOne.title}</h2>
          <h3 className="subheader">{sectionOne.subtitle}</h3>
          <div className="flexCols">
            <p>{sectionOne.columnOne}</p>
            <p>{sectionOne.columnTwo}</p>
          </div>
        </div>
      </div>
      {/* 4 categories */}
      <div className="container blue-bg">
        <div className="section">
          <h2>4 categories</h2>
          <h3 className="subheader">
            researched & analyzed to determine the GOAT 100
          </h3>
          <div className="criteria-flex">
            <div className="category-label a-label">
              <div className="label" style={{'marginRight': '-32px'}}>On-court success</div>
            </div>
            <div className="category-label b-label">
              <div className="label">Longevity & consistency</div>
            </div>
            <div className="category-label c-label">
              <div className="label">Off-court recognition</div>
            </div>
            <div className="category-label d-label">
              <div className="label" style={{'marginBottom': '16px'}}>Influence</div>
            </div>
          </div>
          <Link to='/Ranking#criteria' className='button button-green'>Find out more</Link>
        </div>
      </div>
      {/* TODO: Current standings link to ranking page */}
      {/* <div className="container">
        <div className="section">
        </div>
      </div> */}
      {/* TODO: Current events */}
      {/* <div className="container blue-bg">
        <div className="section">
          <h2>current events</h2>
          <h3 className="subheader">updates</h3>
        </div>
      </div> */}
      {/* Newsletter */}
      <div className="container green-bg">
        <div className="section newsletter">
          <img src={logo} width="50%" />
          <h2>sign-up below for TG100 updates</h2>
          <p>
            *Stay up to date with <em>TG100</em> newsletters
          </p>
          <NewsletterInput />
        </div>
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        sectionOne={frontmatter.sectionOne}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        sectionOne {
          title
          subtitle
          columnOne
          columnTwo
        }
      }
    }
  }
`;
